<template>
  <div class="footer">
    <a href="https://twitter.com/defimeLabs" target="_blank">
      <i-twitter theme="outline" size="24" fill="#333" />
    </a>
    <a href="">
      <span class="i-icon i-icon-medium">
        <svg
          width="24"
          height="24"
          viewBox="0 0 256 146"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid"
        >
          <g>
            <path
              d="M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z"
              fill="#000000"
            ></path>
          </g>
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "nav",
};
</script>
<style scoped lang="less">
.footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin: 0 20px;
    &:hover {
      color: steelblue;
    }
  }
}
</style>
