import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import { install } from '@icon-park/vue/es/all';
import '@icon-park/vue/styles/index.css';
import anime from 'animejs';
import Avataaars from 'vuejs-avataaars'


import '@/style/root.css'
import '@/style/screen.css'
import router from './router'
import store from './store'

Vue.component('Avataaars', Avataaars)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$anime = anime

install(Vue, 'i');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
