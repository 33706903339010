<template>
  <div class="logo-wrapper">
    <img class="logo" alt="denode logo" :src="logo" />
    <span>DefimeLabs</span>
  </div>
</template>

<script>
const logo = require("@/assets/logo.png");
export default {
  name: "logo",
  data() {
    return {
      logo,
    };
  },
};
</script>
<style lang="less" scoped>
.logo-wrapper {
  height: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: 'Lobster', 'Courier New', Courier, monospace;
    margin-left: 10px;
    font-size: 30px;
    font-weight: bold;
    color: var(--text-main);
  }
}
img.logo {
  max-width: 80%;
  max-height: 80%;
}
@media screen and (max-width: 600px) {
  .logo-wrapper{
    span{
      display: none;
    }
  }
  img.logo {
    max-height: 65%;
  }
}
</style>
