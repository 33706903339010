<template>
  <div id="nav">
    <Logo />
    <div class="links">
      <router-link to="/">Home</router-link>
      <span class="separator">|</span>
      <router-link to="/try">Try</router-link>
      <span class="separator">|</span>
      <router-link to="/about">About</router-link>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "nav",
  components: {
    Logo,
  },
};
</script>
<style scoped lang="less">
#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 60px;
  width: 80%;
  max-width: 960px;
  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    font-family: xyp8, "Courier New", Courier, monospace;
    font-size: 30px;
    &.router-link-exact-active {
      color: var(--text-header);
    }
  }
  .separator {
    margin: 10px;
  }
}
@media screen and (max-width: 600px) {
  #nav .links{
    font-size: 20px;
  }
  #nav a{
    font-size: 20px;
  }
}
</style>
