<template>
  <div class="tomo">
    <h2>Yet another way to connect web3 & metaverse</h2>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>
<style scoped lang="less">
.tomo {
  font-family: "DM Mono", monospace;
  color: var(--text-main);
  font-size: 35px;
  text-align: center;
  h2 {
    margin-top: 50px;
    font-family: Lobster, Arial, sans-serif;
    line-height: 100%;
    text-align: center;
    letter-spacing: 6px;
  }
}

</style>
