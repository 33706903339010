<template>
  <div id="app">
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>

<style lang="less">
#app {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  justify-content: space-between;
  background-image: url("./assets/bg.webp");
  background-color: var(--main-white);
}
@media screen and (max-width: 600px) {
  #app {
    background-size: cover;
  }
}
</style>
