<template>
  <div class="home">
    <Tomo />
  </div>
</template>

<script>
import Tomo from '@/components/Tomo'
export default {
  name: 'Home',
  components: {
    Tomo
  }
}
</script>
